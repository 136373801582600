import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { ColumnLayout } from "@amzn/awsui-components-react";
import React from "react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import ValueWithLabel from "shared/programs/migration-acceleration-program/2024/fund-request/components/fields/ValueWithLabel";
import InfoLink from "../../../../../../../../../../shared/components/InfoLink";
import { IReadOnlyComponentProps } from "../../../../../types/SchemaTypes";
import { display } from "shared/util/common/display";
import { longLocalDateFormat } from "shared/util/common/date";
import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import { generateOpportunityIdLink } from "shared/util/common/generateOpportunityIdLink";
import { HelpPanelContentContext } from "shared/util/context/help/HelpContext";
import Config from "config";

const ReadOnlyOpportunityDetails = ({
  data,
  actions = <></>,
}: IReadOnlyComponentProps) => {
  const opportunity = data && data["opportunity"];
  const { setHelpPanelContent } = React.useContext(HelpPanelContentContext);

  const OpportunityLink = ({
    opportunity,
  }: {
    opportunity: IGenericObject;
  }) => {
    if (!opportunity) return "-";
    const aceOpportunityId = opportunity.aceOpportunityId;
    const friendlyOpportunityId = opportunity.userFriendlyId;
    const baseUrl = Config.opportunityBaseUrl;
    return generateOpportunityIdLink({
      aceOpportunityId,
      friendlyOpportunityId,
      baseUrl,
    });
  };

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <InfoLink
              onFollow={() => {
                setHelpPanelContent("wizard.review.opportunity");
              }}
            />
          }
          actions={actions}
        >
          Opportunity details
        </Header>
      }
    >
      <SpaceBetween size="xs">
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Opportunity ID">
              <OpportunityLink opportunity={opportunity} />{" "}
            </ValueWithLabel>
            <ValueWithLabel label="Opportunity ownership">
              {display({ data: opportunity, key: "ownership" })}
            </ValueWithLabel>
            <ValueWithLabel label="ACE validation stage">
              {display({ data: opportunity, key: "aceValidationStage" })}
            </ValueWithLabel>
            <ValueWithLabel label="Industry vertical">
              {display({ data: opportunity, key: "industryVertical" })}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Customer company name">
              {display({ data: opportunity, key: "customerCompanyName" })}
            </ValueWithLabel>
            <ValueWithLabel label="Target close date">
              {
                display({
                  data: opportunity,
                  key: "targetCloseDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Status">
              <StatusIndicator>
                {display({ data: opportunity, key: "status" })}
              </StatusIndicator>
            </ValueWithLabel>
            <ValueWithLabel label="Business unit">
              {display({
                data: opportunity,
                key: "customerBizUnit",
              })}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Opportunity owner">
              {display({ data: opportunity, key: "ownerName" })}
            </ValueWithLabel>
            <ValueWithLabel label="Creation date">
              {
                display({
                  data: opportunity,
                  key: "createdDate",
                  format: (value) => longLocalDateFormat(value),
                }) as string
              }
            </ValueWithLabel>
            <ValueWithLabel label="Partner development manager">
              {display({ data: opportunity, key: "partnerDevelopmentManager" })}
            </ValueWithLabel>
            <ValueWithLabel label="Territory">
              {display({ data: opportunity, key: "territory" })}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
        <ValueWithLabel label="Project description">
          {display({ data: opportunity, key: "projectDescription" })}
        </ValueWithLabel>
      </SpaceBetween>
    </Container>
  );
};

export default ReadOnlyOpportunityDetails;
