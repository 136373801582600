import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";

import {
  fundRequestDuplicatesColumnDefinitions,
  fundRequestDuplicatesColumnDefinitionsWithDuplicateReason,
  searchableColumns,
} from "./tableConfig";
import { Box } from "@amzn/awsui-components-react";
import { getFundRequestDuplicates } from "../../../../util/services/data/InternalDataService";
import TableEmptyState from "../../../common/TableEmptyState";
import TableNoMatchState from "../../../common/TableNoMatchState";
import { filterItem } from "./util";
import useFeatureFlag from "../../../../../shared/util/hooks/useFeatureFlag";
import { FEATURE_FLAG_ENUMS } from "../../../../../shared/util/constants/featureFlagValues";

export const FundRequestDuplicatesTable = forwardRef(
  ({ getDuplicateFundRequests }, ref) => {
    const pageSize = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [duplicates, setDuplicates] = useState([]);
    const [columns, setColumns] = useState(
      fundRequestDuplicatesColumnDefinitions
    );

    const dupEnabled = useFeatureFlag(
      FEATURE_FLAG_ENUMS.AGI_DETERMINISTIC_DUPE_CHECKS_ENABLED
    );

    useEffect(async () => {
      const duplicateFundRequests = await getDuplicateFundRequests({
        getFundRequestDuplicates: getFundRequestDuplicates,
        setIsLoading,
      });
      setDuplicates(duplicateFundRequests);
      setIsLoading(false);
      setColumns(
        dupEnabled
          ? fundRequestDuplicatesColumnDefinitionsWithDuplicateReason
          : fundRequestDuplicatesColumnDefinitions
      );
    }, []);

    const { items, actions, collectionProps, filterProps, paginationProps } =
      useCollection(duplicates || [], {
        pagination: { pageSize },
        empty: <TableEmptyState resourceName="duplicates" />,
        filtering: {
          noMatch: (
            <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
          ),
          filteringFunction: (item, filteringText) => {
            return filterItem({ searchableColumns, item, filteringText });
          },
        },
        selection: {},
      });

    return (
      <div ref={ref}>
        <Table
          {...collectionProps}
          loading={isLoading}
          loadingText="Loading possible fund request duplicates ..."
          items={items}
          columnDefinitions={columns}
          wrapLines={true}
          empty={
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No fund request duplicates available.
              </Box>
            </Box>
          }
          filter={
            <Grid
              gridDefinition={[
                { colspan: 4 },
                { colspan: 1 },
                { colspan: 1 },
                { colspan: 1 },
                { colspan: 1 },
              ]}
            >
              <Input
                type="search"
                value={filterProps.filteringText}
                onChange={(event) => {
                  actions.setFiltering(event.detail.value);
                }}
                placeholder="Enter search criteria..."
                label="Search duplicates"
                ariaDescribedby={null}
              />
            </Grid>
          }
          header={
            <Header counter={items.length + "/" + duplicates.length}>
              Fund Request Duplicates
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
        />
      </div>
    );
  }
);

FundRequestDuplicatesTable.displayName = "FundRequestDuplicates";

FundRequestDuplicatesTable.propTypes = {
  getDuplicateFundRequests: PropTypes.func,
};
