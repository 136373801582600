import { MAX_FILE_SIZE_IN_BYTES } from "external/programs/migration-acceleration-program/2024/fund-request/components/create/wizard/Steps/Artifacts/util";
import { hasError, retryApiCall } from "shared/util/services/data/DataService";
import {
  deleteFile,
  detachFileFromCashClaim,
  detachFileFromFundRequest,
  getUploadFileUrl,
  uploadFile,
} from "external/util/services/data/FundRequestService";
import { getFundRequestId } from "external/util/common/helper";
import { IArtifactFileModel } from "shared/programs/migration-acceleration-program/2024/fund-request/components/attachments/types/fileTypes";

import { IGenericObject } from "shared/programs/migration-acceleration-program/2024/fund-request/types/CommonTypes";
import {
  FormError,
  handleApiError,
  Result,
  ResultType,
  Success,
} from "shared/util/api/util";

export enum FileChangeType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export const FILE_UPLOAD_FAILURE_MESSAGE =
  "Failed to upload the file. Please try to upload the file again.";

export const FILE_DELETE_FAILURE_MESSAGE =
  "Failed to delete the file. Please try to delete the file again.";

export const fileChangeHandler = ({ type }: { type: FileChangeType }) => {
  const handle = {
    [FileChangeType.ADD]: handleAddFile,
    [FileChangeType.REMOVE]: handleRemoveFile,
  };
  return handle[type];
};

export interface IHandleAddFile {
  previous: File[];
  current: File[];
  claimId?: string;
  supportingDocumentType?: string;

  attach: ({
    body,
    fundRequestId,
  }: {
    body: unknown;
    fundRequestId: unknown;
  }) => Promise<IGenericObject>;
}

export const handleAddFile = async ({
  previous,
  current,
  claimId,
  supportingDocumentType,
  attach,
}: IHandleAddFile): Promise<Result<ResultType>> => {
  try {
    const fileToAdd = identifyRemainderFile({
      superset: current,
      subset: previous,
    });

    if (fileToAdd.size > MAX_FILE_SIZE_IN_BYTES) {
      return new Result<FormError>({
        success: false,
        payload: new FormError(
          "Failed to upload file. The file size is greater than 10MB."
        ),
      });
    }

    let fileId = "";
    const getUrlPayload = {
      file_name: fileToAdd.name,
      file_type: supportingDocumentType,
      file_details: {
        file_size: fileToAdd.size,
        last_updated_time: new Date(fileToAdd.lastModified).toLocaleString(),
        expire_time: new Date(
          fileToAdd.lastModified + 1000000000000
        ).toLocaleString(),
      },
    };

    // @ts-expect-error Still in javascript, so the implementation details don't matter
    const getUrlResponse = await retryApiCall({
      callApi: getUploadFileUrl(getUrlPayload, claimId),
    });

    if (hasError(getUrlResponse)) {
      return handleApiError(getUrlResponse.errorType)(getUrlResponse);
    }

    fileId = getUrlResponse.fileId;

    // @ts-expect-error Still in javascript, so the implementation details don't matter
    const uploadFileResponse = await retryApiCall({
      callApi: uploadFile(getUrlResponse.url, fileToAdd, fileId),
    });

    // The actual file upload is a direct axios call to S3, so we just get the raw HTTP response.
    if (!uploadFileResponse.ok) {
      return new Result<FormError>({
        success: false,
        payload: new FormError(FILE_UPLOAD_FAILURE_MESSAGE),
      });
    }

    const fundRequestId = getFundRequestId();

    const attachEventPayload = {
      fileId,
      fundRequestId: fundRequestId,
    };

    // @ts-expect-error Still in javascript, so the implementation details don't matter
    const attachEventResponse = await retryApiCall({
      callApi: attach({
        body: attachEventPayload,
        fundRequestId: fundRequestId,
      }),
    });

    if (hasError(attachEventResponse)) {
      return handleApiError(attachEventResponse.errorType)(attachEventResponse);
    }
    return new Result<Success>({ payload: { message: fileId } });
  } catch (err) {
    console.error(err);
    return new Result<FormError>({
      success: false,
      payload: new FormError(FILE_UPLOAD_FAILURE_MESSAGE),
    });
  }
};

const handleRemoveFile = async ({
  claimId,
  current,
  fileIdToAttributes,
}: {
  claimId?: string;
  current: File[];
  fileIdToAttributes: IArtifactFileModel;
}): Promise<Result<ResultType>> => {
  try {
    const fileId = identifyRemovedFileId({
      files: current,
      fileIdToAttributes,
    });

    if (!fileId)
      return new Result<FormError>({
        success: false,
        payload: new FormError(FILE_DELETE_FAILURE_MESSAGE),
      });

    // @ts-expect-error Still in javascript, so the implementation details don't matter
    const deleteFileResponse = await retryApiCall({
      callApi: deleteFile(fileId),
    });

    if (hasError(deleteFileResponse)) {
      return handleApiError(deleteFileResponse.errorType)(deleteFileResponse);
    }

    let detachEventResponse = {};

    if (claimId) {
      // @ts-expect-error Still in javascript, so the implementation details don't matter
      detachEventResponse = await retryApiCall({
        callApi: detachFileFromCashClaim({
          body: {
            fundRequestId: getFundRequestId(),
            fileId,
            fundClaimId: claimId,
          },
          fundRequestId: getFundRequestId(),
          fundClaimId: claimId,
        }),
      });
    } else {
      // @ts-expect-error Still in javascript, so the implementation details don't matter
      detachEventResponse = await retryApiCall({
        callApi: detachFileFromFundRequest({
          body: { fundRequestId: getFundRequestId(), fileId },
          fundRequestId: getFundRequestId(),
        }),
      });
    }

    if (hasError(detachEventResponse)) {
      // @ts-expect-error Still in javascript, so the implementation details don't matter
      return handleApiError(detachEventResponse.errorType)(detachEventResponse);
    }
    return new Result<Success>({ payload: { message: fileId } });
  } catch (err) {
    console.error(err);
    return new Result<FormError>({
      success: false,
      payload: new FormError(FILE_DELETE_FAILURE_MESSAGE),
    });
  }
};

const identifyRemainderFile = ({
  superset,
  subset,
}: {
  superset: File[];
  subset: File[];
}): File => {
  const [remainderFile] = superset.filter((previousFile: File) => {
    return !subset.some((currentFile: File) => {
      return (
        equals(previousFile.lastModified, currentFile.lastModified) &&
        equals(previousFile.name, currentFile.name) &&
        equals(previousFile.size, currentFile.size)
      );
      // Temporarily removing, because the API does not currently give us "type"
      // && equals(previousFile.type, currentFile.type);
    });
  });
  return remainderFile;
};

export const identifyNontrackedFile = ({
  files,
  fileIdToAttributes,
}: {
  files: File[];
  fileIdToAttributes: IArtifactFileModel;
}) => {
  const [nonTrackedFile] = files.filter((file) => {
    return !Object.values(fileIdToAttributes).some((value) => {
      return (
        equals(file.size, value.size) &&
        equals(file.name, value.name) &&
        equals(file.lastModified, value.lastModified)
      );
    });
  });
  return nonTrackedFile;
};

const identifyRemovedFileId = ({
  files,
  fileIdToAttributes,
}: {
  files: File[];
  fileIdToAttributes: IArtifactFileModel;
}) => {
  const [removedFileAttributes] = Object.values(fileIdToAttributes).filter(
    (attributes) => {
      return !files.some((file) => {
        return (
          equals(file.size, attributes.size) &&
          equals(file.name, attributes.name) &&
          equals(file.lastModified, attributes.lastModified)
        );
      });
    }
  );

  const [removed] = Object.entries(fileIdToAttributes)
    .filter(([, attributes]) => {
      return (
        equals(attributes.name, removedFileAttributes.name) &&
        equals(attributes.size, removedFileAttributes.size) &&
        equals(attributes.lastModified, removedFileAttributes.lastModified)
      );
    })
    .map(([fileId]) => fileId);
  return removed;
};

export const getArtifacts = ({
  documents,
  filter,
}: {
  documents: IGenericObject;
  filter: (actualType: string) => boolean;
}): {
  files: File[];
  fileIdToAttributes: IGenericObject;
} => {
  const fileIdToAttributes: IGenericObject = {};

  const files =
    documents &&
    Object.keys(documents)
      .filter((fileId) => {
        return filter(documents[fileId].fileType);
      })
      .map((fileId) => {
        const fileDetails = documents[fileId];
        const name = fileDetails["name"];
        const lastModified = new Date(fileDetails["uploadedDate"]).getTime();
        const size = fileDetails["size"];

        fileIdToAttributes[fileId] = { name, size, lastModified };

        return new File([new Blob([new ArrayBuffer(size)])], name, {
          lastModified,
        });
      });

  return {
    files,
    fileIdToAttributes,
  };
};

export const equals = (
  firstValue: string | number,
  secondValue: string | number
): boolean => firstValue === secondValue;
